"use client";

import { useClientTranslation } from "@rimo/i18n/useClientTranslation";

import type { FC, PropsWithChildren, ReactNode } from "react";
import { useState, useContext, useEffect } from "react";

import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";

import { Box, Button, Stack, CircularProgress, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";

import { UserContext } from "../contexts/UserContext";
import { useInjectUserIntoReactApp } from "../hooks/useInjectUserIntoReactApp";
import { isSNS, signInWithSocial } from "../services/firebase/auth/signIn";
import { QueryStringSamlProviderIdKey } from "../services/firebase/auth/signUp";
import { type GetRedirectResultCallback, getRedirectSignedInUser } from "../services/firebase/auth/user";
import Sentry from "../utils/sentry";
import { Constant } from "../constants/constant";
import { sendGoogleConversionPlain } from "../services/conversion/Google";
import { Body } from "@rimo/ui-old";
import { Apps } from "@rimo/frontend/types/services";
import { useNotificationDispatcher } from "../contexts/NotificationContext";
import { isProd } from "../utils/system";
import voiceApi from "../api/voice";
import { NTReactNode } from "@rimo/i18n/types";

const QueryStringProviderKey = "provider" as const;

// alias of VoiceRedirectLogin and AIEditorRedirectLogin components
export const RedirectLogin: FC<{ apps: Apps }> = ({ apps }) => {
  switch (apps) {
    case "voice":
      return <VoiceRedirectLogin />;
    case "ai-editor":
      return <AIEditorRedirectLogin />;
    default:
      return null;
  }
};
export function VoiceRedirectLogin() {
  return (
    <FirebaseAuthenticationRedirectResult apps="voice">
      <ProgressUserContext apps="voice" fallback={<MoveToExternalApplication apps="voice" />} />
    </FirebaseAuthenticationRedirectResult>
  );
}
export function AIEditorRedirectLogin() {
  return (
    <FirebaseAuthenticationRedirectResult
      apps="ai-editor"
      onSignIn={voiceApi.enableWritingModeOn}
      onSignUp={voiceApi.enableWritingModeOn}
    >
      <ProgressUserContext apps="ai-editor" fallback={<MoveToExternalApplication apps="ai-editor" />} />
    </FirebaseAuthenticationRedirectResult>
  );
}

/**
 * Firebase Authenticationからリダイレクト元からのセッション情報を取得する
 * onAuthStateChanged()が先に呼び出されることを防ぐため、UserAuthコンポーネントよりも先に呼び出している
 * @param param0
 * @returns
 */
const FirebaseAuthenticationRedirectResult: FC<
  PropsWithChildren<{
    apps: Apps;
    onSignUp?: GetRedirectResultCallback | undefined;
    onSignIn?: GetRedirectResultCallback | undefined;
  }>
> = ({ apps, children, onSignIn, onSignUp }) => {
  const { customT } = useClientTranslation();
  const [result, setResult] = useState<Awaited<ReturnType<typeof getRedirectSignedInUser>> | Error | undefined>(
    undefined
  );

  useEffect(() => {
    (async () => {
      try {
        const resp = await getRedirectSignedInUser({ onSignIn, onSignUp });
        setResult(resp);
      } catch (err) {
        Sentry.captureException(err);
        if (err instanceof Error) {
          if (!isProd) console.error("got an error during getRedirectResult() function", err);
          setResult(err);
        } else {
          if (!isProd) console.error("unknown error", err);
          setResult(new Error("occurred an error during getRedirectResult()"));
        }
      }
    })();
  }, [onSignIn, onSignUp]);

  const router = useRouter();
  const inject = useInjectUserIntoReactApp();
  const dispatch = useNotificationDispatcher();

  // リダイレクト結果確認中
  if (result === undefined) {
    return null;
  }
  // ログイン済みユーザーがURL直叩きや別画面から遷移した場合はtopPageへ移動する
  if (result instanceof Error) {
    const error = result;
    dispatch({
      type: "ERROR",
      payload: {
        message: customT(error.message),
      },
    });
    router.replace(getPreventRoute(apps));
    return null;
  }
  if (result !== null) {
    if (result.kind === "reload") {
      router.replace(getNextRoute(apps));
      return null;
    } else {
      sendGoogleConversionPlain({ event: "login_karte", status: true });
      // ソーシャルログイン時(サインアップ/サインイン)
      inject(result);
      // inject()関数により、リダイレクト処理がされるため、当該コンポーネントでは `<PageFirebaseUserDispatch />` のみでよい
      return <Page apps={apps} />;
    }
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const ProgressUserContext: FC<{ apps: Apps; fallback: ReactNode }> = ({ apps, fallback }) => {
  const router = useRouter();
  const {
    userState: { user, isLoaded },
  } = useContext(UserContext);

  // ローディング中
  if (!isLoaded) {
    return null;
  }
  // ログインしていたら、TopPageへ遷移させる
  if (user || getAuth().currentUser) {
    // ログイン済みユーザーがURL直叩きや別画面から遷移した場合はtopPageへ移動する
    router.replace(getNextRoute(apps));
    return null;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{fallback as NTReactNode}</>;
};

const MoveToExternalApplication: FC<{ apps: Apps }> = ({ apps }) => {
  const { customT } = useClientTranslation();
  const router = useRouter();
  const searchParams = useSearchParams();
  const dispatch = useNotificationDispatcher();

  // 画面遷移からの場合、ソーシャルログインを実施する
  const provider = searchParams?.get(QueryStringProviderKey);
  const v = searchParams?.get(QueryStringSamlProviderIdKey);
  const isValid = typeof v === "string" && Boolean(v);
  const samlProviderId = isValid ? v : undefined;
  const hasProviderError = !isSNS(provider) || (provider === "saml" && !samlProviderId);
  // 不明なProviderの値を渡した時は "/about" に移動
  // samlProviderIdが undefined、空文字、配列の場合は成立しないため "/about" に移動
  if (hasProviderError) {
    router.replace(getPreventRoute(apps));
    dispatch({
      type: "ERROR",
      payload: {
        message: customT("認証に失敗しました。設定を確認してください。"),
      },
    });
  } else {
    signInWithSocial(provider, samlProviderId);
  }

  return null;
};

const Page: FC<{ apps: Apps }> = ({ apps }) => {
  const { customT } = useClientTranslation();
  const router = useRouter();
  return (
    <div>
      <Box sx={{ position: "absolute", top: 12, right: 12 }}>
        <Button onClick={() => router.push(Constant.Url.Internal.Logout)} variant="text">
          {customT("ログアウト")}
        </Button>
      </Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        rowGap={2}
        sx={{
          bgcolor: "background.experimental.layeredDefault",
          minHeight: "100vh",
          overflow: "hidden",
        }}
      >
        <CircularProgress size={64} thickness={1} />
        {apps === "voice" ? (
          <>
            <Typography color="text.primary" fontSize={24}>
              {customT("Rimo Voice ログイン処理中")}
            </Typography>
            <Link href="/">
              <Button size="large">{customT("ノート一覧画面へ移動する")}</Button>
            </Link>
          </>
        ) : apps === "ai-editor" ? (
          <>
            <Body>{customT("Rimo AI Editor ログイン処理中")}</Body>
            <Link href={Constant.Url.AIEditor.List}>
              <Button size="large">{customT("インタビュー一覧画面へ移動する")}</Button>
            </Link>
          </>
        ) : null}
      </Stack>
    </div>
  );
};

const getNextRoute = (apps: Apps): string => {
  switch (apps) {
    case "ai-editor":
      return Constant.Url.AIEditor.List;
    case "voice":
      return Constant.Url.Internal.TopPage;
    default:
      return Constant.Url.Internal.TopPage;
  }
};

const getPreventRoute = (apps: Apps): string => {
  switch (apps) {
    case "ai-editor":
      return Constant.Url.AIEditor.UnauthorizedTop;
    case "voice":
      return "/";
    default:
      return "/";
  }
};
