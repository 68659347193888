import {
  installGtag,
  googleUploadConversion,
  sendGoogleAwConversion,
  sendSignUpEvent,
  sendGoogleFormConversion,
} from "./Google";
import { installYahooTag, sendYahooFormConversion } from "./YahooTag";
import { facebookPixelPurchase, facebookPixelCompleteRegistration } from "./FacebookPixel";

export function installConversionTags() {
  installGtag();
  installYahooTag();
}

export function uploadConversion(mediaType: string, amounts: number, uid?: string) {
  googleUploadConversion(mediaType, amounts);
  sendGoogleAwConversion();
  if (uid) {
    facebookPixelPurchase(amounts, mediaType, uid);
  }
}

export async function signUpConversion(providerId: string, uid: string) {
  sendSignUpEvent(providerId);
  sendGoogleAwConversion();
  await facebookPixelCompleteRegistration(uid);
}

export function sendFormConversion() {
  sendGoogleFormConversion();
  sendYahooFormConversion();
}
